.arrow-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    .dashed-line{
        width: 1px;
        border-right: 2px dashed grey;
        height: 100%;
    }
}