.disclaimer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 100;
  &.open {
    opacity: 1;
    visibility: visible;
  }
}

.disclaimer-modal {
  max-width: 450px;
  background-color: var(--color-card);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 80px 24px;
  border-radius: 10px;
  border: 1px solid var(--color-border-second);
  z-index: 100;

  .title {
    font-size: 24px;
    font-weight: 700;
    color: var(--color-primary);
    margin-bottom: 30px;
  }

  .description {
    font-size: 16px;
    color: var(--color-muted);
    margin-bottom: 42px;
  }

  .btn-container {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-self: end;

    .terms-of-service-link {
      color: var(--color-muted);
      text-decoration: none;
      font-size: 14px;
      font-weight: 300;
      padding: 12px;
    }

    .accept-btn {
      background-color: var(--color-secondary);
      font-size: 12px;
      color: var(--color-primary);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      border: 1px solid var(--color-primary);
      padding: 12px 24px;
    }
  }
}


@media (max-width: 700px) {
  .disclaimer-modal{
    width: 90%;
    max-width: none;
  }  
}