.reward-details__container {
  .reward-details {
    border: 2px solid var(--color-border-second);
    border-radius: 10px;
    padding: 16px 12px;
    margin-bottom: 12px;
    background-color: var(--color-secondary);

    .reward-details__about {
      .reward-details__image {
        width: 100%;
        img {
          width: 100%;
        }
      }

      .reward-details__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;

        .reward-details__title {
          .title {
            color: var(--color-primary);
          }
          .subtitle {
            color: var(--color-text);
          }
        }

        .reward-details__chains {
          text-align: right;
          p {
            color: var(--color-text);
          }

          .reward-details__chain-list {
            display: flex;
            align-items: center;
            gap: 4px;

            img {
              width: 40px;
              aspect-ratio: 1/1;
            }
          }
        }
      }

      .reward-details__node-info {
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .reward-details__node-value {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-bottom: 12px;

          .node-text {
            background-color: var(--color-card);
            border: 2px solid var(--color-border-second);
            border-radius: 50px;
            padding: 2px 6px;
            color: var(--color-text);
            font-size: 14px;
          }

          .reach {
            display: flex;
            align-items: center;
            gap: 2px;
            background-color: var(--color-card);
            border: 2px solid var(--color-border-second);
            border-radius: 50px;
            padding: 2px 4px;
            color: var(--color-text);
            font-size: 14px;

            .person-icon {
              width: 12px;
              height: 12px;
            }
          }
        }

        .reward-details__actions {
          display: flex;
          align-items: center;
          gap: 12px;

          .reward-details__button {
            background-color: var(--color-card);
            border: 2px solid var(--color-border-second);
            border-radius: 5px;
            padding: 12px 24px;
            color: var(--color-text);
            cursor: pointer;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            color: var(--color-text);

            &:focus {
              outline: none;
            }
            &:hover {
              text-decoration: none;
            }
          }
        }
      }

      .reward-details__claim-note {
        color: var(--color-muted);
      }
    }
  }
  .rewards-tier {
    border: 2px solid var(--color-border-second);
    border-radius: 10px;
    padding: 12px;
    margin-bottom: 12px;
    background-color: var(--color-secondary);

    .heading {
      color: var(--color-text);
      margin-bottom: 12px;
    }

    .rewards-tier__image {
      border: 2px solid var(--color-border-second);
      border-radius: 10px;
      padding: 12px;
      background-color: var(--color-card);
      width: 100%;
      img {
        width: 100%;
      }
    }
  }

  .rewards__bottom-content {
    display: flex;
    align-items: flex-start;
    gap: 12px;

    .how-to-claim {
      flex: 1;
      width: 100%;
      border: 2px solid var(--color-border-second);
      border-radius: 10px;
      padding: 12px;
      background-color: var(--color-secondary);
      padding-bottom: 24px;

      .title {
        color: var(--color-text);
      }

      .sub-title {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--color-links);
        text-decoration: underline;
        margin-bottom: 12px;

        .text {
          font-size: 14px;

          &:hover {
            color: var(--color-links);
          }
        }
      }

      .how-to-claim-img {
        border: 2px solid var(--color-border-second);
        border-radius: 10px;
        background-color: var(--color-card);
        width: 100%;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .claim-card {
      border: 2px solid var(--color-border-second);
      border-radius: 10px;
      padding: 12px;
      background-color: var(--color-secondary);
      max-width: 280px;

      .title {
        color: var(--color-text);
        margin-bottom: 12px;
      }

      .sub-title {
        color: var(--color-muted);
        margin-bottom: 12px;
      }

      .description {
        color: var(--color-muted);
        font-size: 12px;
      }

      .claim-button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid var(--color-border-second);
        border-radius: 10px;
        width: 100%;
        margin-top: 12px;
        padding: 6px 12px;
        background-color: var(--color-card);
        color: var(--color-primary);
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }

      .copy-code {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 2px solid var(--color-border-second);
        border-radius: 10px;
        padding: 6px 12px;
        background-color: var(--color-card);
        margin-bottom: 12px;
        color: var(--color-muted);

        .copy-icon {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }

      .claim-btn {
        color: var(--color-primary);
      }
      .coming-soon-btn {
        color: var(--color-muted);
        cursor: default;
      }

      .claim-btn,
      .coming-soon-btn {
        border: 2px solid var(--color-border-second);
        border-radius: 10px;
        padding: 6px 12px;
        background-color: var(--color-card);
        margin-bottom: 12px;
        width: 100%;

        &:focus {
          outline: none;
        }
      }

      .claim-step-img {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;

        img {
          width: 100px;
          height: 100px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .reward-details__container {
    .reward-details {
      .reward-details__about {
        .reward-details__info {
          flex-direction: column;
          gap: 20px;
          align-items: flex-start;

          .reward-details__chains {
            text-align: left;
            margin-bottom: 20px;
          }
        }

        .reward-details__node-info {
          flex-direction: column;
          gap: 12px;
          align-items: flex-start;

          .reward-details__actions {
            .reward-details__button {
              padding: 12px 16px;
            }
          }
        }
      }
    }

    .rewards-tier {
      .rewards-tier__image {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    .rewards__bottom-content {
      flex-direction: column;
      gap: 12px;

      .how-to-claim,
      .claim-card {
        max-width: none;
      }
    }
  }
}

@media (max-width: 1200px) {
  .reward-details__container {
    .rewards-tier {
      .rewards-tier__image {
        grid-template-columns: repeat(4, 1fr); /* 4 columns */
      }
    }
  }
}
@media (max-width: 576px) {
  .reward-details__container {
    .rewards-tier {
      .rewards-tier__image {
        grid-template-columns: repeat(3, 1fr); /* 4 columns */
      }
    }
  }
}

// Rabble rewards css

.reward-details__container {
  .rabble-reward-text-content {
    .rewards__earned {
      border: 2px solid var(--color-border-second);
      border-radius: 10px;
      padding: 12px;
      background-color: var(--color-secondary);
      margin-bottom: 12px;

      .heading {
        color: var(--color-text);
        margin-bottom: 12px;
        font-size: 24px;
        font-weight: 600;
      }

      .rewards-stats {
        border: 2px solid var(--color-border-second);
        border-radius: 10px;
        padding: 12px;
        background-color: var(--color-card);
        .sub-text {
          color: var(--color-text);
          margin-bottom: 6px;
        }

        .rabble-stats {
          display: flex;
          align-items: center;
          gap: 12px;
          margin-bottom: 12px;

          .user-joined,
          .earned-amount-container {
            border: 2px solid var(--color-border-second);
            border-radius: 10px;
            padding: 12px;
            background-color: var(--color-secondary);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            min-width: 260px;
            flex: 1;

            .heading {
              font-size: 20px;
              font-weight: 600;
              color: var(--color-text);
              margin-bottom: 12px;
            }

            .user-joined-stats,
            .earned-amount {
              display: flex;
              align-items: center;
              gap: 12px;

              .separator {
                height: 36px;
                background-color: var(--color-muted);
                color: var(--color-muted);
                margin-left: 32px;
              }

              .this-month {
                text-align: start;

                .label {
                  color: var(--color-primary);
                  font-size: 18px;
                }

                .text {
                  font-size: 14px;
                  color: var(--color-muted);
                }
              }
            }
          }
        }

        .important-note {
          font-style: italic;
          border: 2px solid var(--color-border-second);
          border-radius: 10px;
          padding: 12px;
          background-color: var(--color-secondary);

          .heading {
            font-size: 14px;
            color: var(--color-links);
            margin-bottom: 4px;
            font-weight: lighter;
          }

          .text-1,
          .text-2 {
            font-size: 14px;
            color: var(--color-muted);
          }
        }
      }
    }

    .claims-detail-container {
      display: flex;
      align-items: stretch;
      gap: 12px;
      .how-to-claim {
        flex: 1;
        width: 100%;
        border: 2px solid var(--color-border-second);
        border-radius: 10px;
        padding: 12px;
        background-color: var(--color-secondary);

        .heading {
          color: var(--color-text);
          margin-bottom: 12px;
          font-size: 24px;
          font-weight: 500;
        }

        .claim-process {
          border: 2px solid var(--color-border-second);
          border-radius: 10px;
          display: flex;
          align-items: center;
          gap: 12px;
          justify-content: center;
          background-color: var(--color-card);
          padding: 50px 24px;

          .first-step,
          .second-step,
          .third-step {
            display: flex;
            align-items: center;
            max-width: 140px;
            text-align: center;
            gap: 4px;

            div {
              display: flex;
              flex-direction: column;
              align-items: center;
            }

            .rounded-icon {
              border: 2px solid var(--color-border-second);
              border-radius: 100px;
              padding: 12px;
              background-color: var(--color-secondary);
              display: flex;
              align-items: center;
              justify-content: center;
              width: 50px;
              height: 50px;

              img {
                width: 20px;
                height: 20px;
              }

              .icon {
                width: 20px;
                height: 20px;
                color: var(--color-primary);
              }
            }
          }
        }
      }

      .claim-card {
        border: 2px solid var(--color-border-second);
        border-radius: 10px;
        padding: 12px;
        background-color: var(--color-secondary);
        max-width: 280px;

        .heading {
          font-size: 24px;
          font-weight: 500;
          color: var(--color-text);
          margin-bottom: 10px;
        }
        .sub-text {
          color: var(--color-muted);
          margin-bottom: 12px;
        }

        .refferer-id-btn {
          background-color: var(--color-card);
          border: 2px solid var(--color-border-second);
          border-radius: 10px;
          padding: 12px 24px;
          color: var(--color-muted);
          cursor: pointer;
          font-size: 14px;
          width: 100%;
          margin-bottom: 12px;
          &:focus {
            outline: none;
          }
        }

        .copy-button {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          background-color: var(--color-card);
          border: 2px solid var(--color-border-second);
          border-radius: 10px;
          padding: 12px 24px;
          color: var(--color-primary);
          cursor: pointer;
          font-size: 14px;
          width: 100%;

          &:focus {
            outline: none;
          }

          .copy-icon {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  .monad {
    align-items: stretch;

    .claim-card {
      min-width: 14rem;

      .claim-text {
        color: var(--color-muted);
        font-size: 12px;
      }

      .reward-earned-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 10px 6px;
        align-items: center;
        text-align: center;
        border: 1px solid var(--color-primary);
        border-radius: 10px;

        .title {
          color: var(--color-primary);
          font-size: 18px;
          font-weight: bold;
        }

        .reward-amount {
          font-size: 20px;
          color: var(--color-primary);
          .reward-earned-footer-text {
            color: var(--color-muted);
            font-size: 12px;
          }
        }
        .sub-title {
          color: white;
          font-size: 15px;
        }
      }
    }
    .how-to-claim.monad-rewards {
      .claim-process {
        display: flex;
        border: 2px solid var(--color-border-second);
        border-radius: 10px;
        background-color: var(--color-card);
        padding: 12px;
        margin-top: 12px;
        gap: 12px;
        height: 90%;

        .steps {
          display: flex;
          flex-direction: column;
          gap: 16px;
          justify-content: space-between;

          .first-step,
          .second-step,
          .third-step {
            display: flex;
            align-items: center;
            gap: 16px;
            color: var(--color-muted);

            &.active-step {
              .icon,
              .step-text {
                color: var(--color-text);
              }
            }

            &.done-step {
              position: relative;
              .icon {
                color: var(--color-primary);
              }
              .step-text {
                color: var(--color-text);
              }

              .check-icon {
                position: absolute;
                left: -10px;
                top: -5px;
              }
            }

            svg {
              min-width: 2.25rem;
              min-height: 2.25rem;
            }

            .active-step path {
              stroke: var(--color-primary);
            }

            .active-step rect {
              stroke: var(--color-primary);
            }

            p.step-text {
            }
          }
        }
      }
    }

    .claim-button {
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;

        .spinner {
          animation: spin 1s linear infinite;
        }
      }
    }
    .monad-rewards.images-container {
      flex: 1;
      width: 100%;
      border: 2px solid var(--color-border-second);
      border-radius: 10px;
      padding: 12px;
      background-color: var(--color-secondary);
      margin-top: 12px;
      margin-bottom: 12px;

      .title {
        color: var(--color-text);
      }

      img {
        margin-top: 12px;
        width: 100%;
        border-radius: 10px;
      }
    }
  }
}

@media (max-width: 768px) {
  .rabble-reward-text-content {
    .rewards__earned {
      .rewards-stats {
        .rabble-stats {
          flex-direction: column;
          .user-joined,
          .earned-amount-container {
            min-width: 100px;
            width: 100%;
          }
        }
      }
    }

    .claims-detail-container {
      flex-direction: column;

      .claim-card {
        width: 100%;
        max-width: none !important;
      }
    }
  }
}


.fastlane-quest-description-points{
  font-size: 14px;
  color: var(--color-muted);
}